import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Loader from "../Loader/Loader";
import useUserRoles from "../../useUserRoles";

const ProtectedRoute = ({ component: Component, exact, path, ...rest }) => {
  const { activeNavigator, isAuthenticated, isLoading, signoutRedirect } =
    useAuth();
  const roles = useUserRoles();

  useEffect(() => {
    if (activeNavigator) return;
    if (isLoading) return;
    if (isAuthenticated && !roles.includes("superadmin")) {
      signoutRedirect();
    }
  }, [activeNavigator, isAuthenticated, isLoading, roles, signoutRedirect]);

  useEffect(() => {
    if (!isAuthenticated || isLoading || !!activeNavigator) return;
    const { location } = window;
    const searchParams = new URLSearchParams(location.search);

    const paramsToRemove = ["state", "code", "session_state", "iss"];
    const paramsThatExists = paramsToRemove.filter((param) =>
      searchParams.has(param)
    );

    if (paramsThatExists.length === 0) return;

    // biome-ignore lint/complexity/noForEach: <explanation>
    paramsThatExists.forEach((param) => searchParams.delete(param));

    window.history.replaceState({}, "", `${location.pathname}?${searchParams}`);
  }, [activeNavigator, isAuthenticated, isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    const url = `${window.location.pathname}`;
    return <Redirect to={`/login?redirectTo=${url}`} />;
  }

  if (!roles.includes("superadmin")) {
    return <Redirect to="/login" />;
  }

  return (
    <Route exact={exact} path={path}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...rest} />
    </Route>
  );
};

export default ProtectedRoute;
