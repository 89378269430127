import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AuthProvider, type AuthProviderProps } from "react-oidc-context";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import SegmentScript from "./analytics/segment/script";
import "./analytics/sentry";
import config from "./config";
import userStore from "./userStore";

const oidcConfig: AuthProviderProps = {
  authority: `${config.keycloakAuthUrl}/realms/${config.keycloakRealm}`,
  client_id: config.keycloakClient,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  userStore,
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      authority={oidcConfig.authority}
      client_id={oidcConfig.client_id}
      redirect_uri={oidcConfig.redirect_uri}
      userStore={oidcConfig.userStore}
    >
      <Provider store={store}>
        <SegmentScript />
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
