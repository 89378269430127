import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import { useAuth } from "react-oidc-context";

const useUserRoles = () => {
  const { user } = useAuth();

  return useMemo(() => {
    if (!user) return null;

    const decoded = jwtDecode(user.access_token) as {
      realm_access: { roles: string[] };
    };

    return decoded?.realm_access?.roles;
  }, [user]);
};

export default useUserRoles;
